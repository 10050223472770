<template>
    <div class="TMP">
        <h1>PLAYGROUND (DEV ONLY)</h1>

        <md-card>
            <md-card-header>
                <div class="md-title">Card</div>
            </md-card-header>

            <md-card-content>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                <br>&nbsp;<br>
                <md-icon>camera</md-icon>
                <br>&nbsp;<br>
                <br>&nbsp;<br>
                IP: {{ ip }}
            </md-card-content>

            <md-card-actions>
                <md-button @click="show=true">Action</md-button>
                <md-button>Action</md-button>
            </md-card-actions>
        </md-card>

        <md-dialog :md-active.sync="show">
            <md-dialog-title>TEST</md-dialog-title>
            <md-dialog-content>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
                <br>&nbsp;<br>
                <md-icon>camera</md-icon>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="show = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
export default {
    props: {},
    data: () => ({
        show: false
    }),
    computed: {
        ip: function () {
            this.$store.dispatch("loadIP")
            return this.$store.state.ip
        }
    }
};
</script>

<style lang="scss" scoped>
.md-card {
    //width: 320px;
    margin: 4px;
    display: inline-block;
    vertical-align: top;
}
</style>
